<template>
    <v-row>
        <v-col cols="12">
            <gmap-map :mapTypeId="'satellite'" :zoom="14" @click="get_mark" :center="center" style="width:100%;  height: 600px;" :options="options">
                <gmap-marker :clickable="true" :key="index" v-for="(m, index) in locationMarkers" :position="m.position"
                    :title="m.title" @click="toggleInfoWindow(m, index)">
                </gmap-marker>
                <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                    @closeclick="infoWinOpen = false">
                    {{ infoContent }}
                </gmap-info-window>
            </gmap-map>
        </v-col>
    </v-row>
</template>
<script>
export default {

    name: "Mapa",
    props: {
        marcas: {
            type: Array
        },
        marca:{
            type:Object
        },
        centralizar:{
            type:Boolean,
            default: false
        },
        read:{
            type:Boolean,
            default: true
        }
    },
    data: () => ({
        result:null,
        origin: {
        },
        center: {
            lat: -2.5005190309483756,
            lng: -44.28711062587991,
            title:''
        },
        options: {
            zoomControl: true,
            mapTypeControl: false,
            backgroundColor: '#000000',
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            styles: {
                default: [],
                hide: [
                    {
                        featureType: 'poi.attraction',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        elementType: 'labels.icon',
                        stylers: [{ visibility: 'off' }],
                    },
                ],
            },
            disableDefaultUi: false
        },
        locationMarkers: [],
        locPlaces: [],
        existingPlace: null,
        usu: null,
        ultima_posicao_banco: null,
        infoContent: '',
        infoWindowPos: null,
        infoWinOpen: false,
        currentMidx: null,
        //optional: offset infowindow so it visually sits nicely on top of our marker
        infoOptions: {
            pixelOffset: {
                width: 0,
                height: -35
            }
        },
    }),
    methods: {
        initMarker(loc) {
            this.existingPlace = loc;
        },
        get_mark(event) {
            const ll = { lat: event.latLng.lat(), lng: event.latLng.lng(), title:'' }
            this.$emit('latlng', ll)
            this.addLocationMarker(ll)
        },
        addLocationMarker(event) {
            
                this.result = event
                if(this.read) {this.locationMarkers = []}
                if(event!==undefined){ 
                    console.log('center',event)
                    this.locationMarkers.push({ position: event, title: event.title });
                    this.locPlaces.push(this.existingPlace);
                    this.existingPlace = null;
                }else{
                    console.log('TITLE É OBRIGATORIO')
                }
            

        },
       
        toggleInfoWindow(marker, idx) {
            this.infoWindowPos = marker.position;
            this.infoContent = marker.title;
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        get_start(){
            if(navigator.geolocation) {
            
            navigator.geolocation.getCurrentPosition(position=>{
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                this.center.title = 'ESTOU AQUI'
                this.addLocationMarker(this.center)
                this.$emit('center', this.center)
            })
            }
            if(this.read){
                this.marcas.forEach(e => {
                    let tmp = e
                    tmp.lat = parseFloat(e.lat)
                    tmp.lng = parseFloat(e.lng)
                    tmp.title = `${e.lat} - ${e.lng}`     
                    this.addLocationMarker(e)
                })
                if(this.marca.lat!==undefined) {
                    this.center = {
                        lat:parseFloat(this.marca.lat),
                        lng:parseFloat(this.marca.lng),
                        title: `${this.marca.paciente} ${this.marca.lat} - ${this.marca.lng}`
                    }
                }
            }else{
                console.log("marca",this.marca)
                let g = {
                    lat:parseFloat(this.marca.lat),
                    lng:parseFloat(this.marca.lng),
                    title:this.marca.title,
                }
                this.addLocationMarker(g)
            }
        }
    },
    mounted() {
        this.get_start()
    }
}
</script>